/**
 *  Google Analytics events functions
**/

var debug = false
var domain = window.location.hostname;

//Library
//items
export function triggerItemTooltipOpenEvent(title){
	triggerGAEvent('Items','Open Tooltip',title);
	/*if(mixpanel!==undefined)mixpanel.track('item_tooltip_view',{title:title});*/
}

export function triggerItemOpenEvent(title){
	triggerGAEvent('Library Items','Open Details',title);
    /*if(mixpanel!==undefined)mixpanel.track('item_open',{title:title});*/
}

export function triggerItemAppLinkEvent(title){
	triggerGAEvent('Library Items','Open App Link',title);
    /*if(mixpanel!==undefined)mixpanel.track('item_link_click',{title:title});*/
}

export function trackSamplePlayEvent(title,id) {
    triggerGAEvent('Library Items','Audio Sample Play',title,id);
    /*if(mixpanel!==undefined)mixpanel.track('audio_sample_play',{title:title,id:id});*/
}

export function trackSamplePauseEvent(title,id) {
    triggerGAEvent('Library Items','Audio Sample Pause',title,id);
    /*if(mixpanel!==undefined)mixpanel.track('audio_sample_pause',{title:title,id:id});*/
}


//navigation
export function triggerLibraryTabSelectionEvent(tabName){
	triggerGAEvent('Library','Tab Navigation',tabName);
    /*if(mixpanel!==undefined)mixpanel.track('library_tab_select',{label:tabName});*/
}

export function triggerLibraryNextPageEvent() {
	triggerGAEvent('Library','Next Page');
    /*if(mixpanel!==undefined)mixpanel.track('library_page_next');*/
}

export function triggerLibraryPreviousPageEvent() {
	triggerGAEvent('Library','Previous Page');
    /*if(mixpanel!==undefined)mixpanel.track('library_page_previous');*/
}



//password change
export function trackPasswordChangeRequestEvent(success){
    triggerGAEvent('Password Change', 'Request',success?'success':'failure');
    /*if(mixpanel!==undefined)mixpanel.track('password_change_request',{success:success});*/
}

export function trackPasswordChangeEvent(success,failure_reason) {
    triggerGAEvent('Password Change','Complete',success?'success':'failure');

    let eventData = {success:success};
    if(failure_reason!==undefined && failure_reason!==null)eventData.reason=failure_reason;
    /*if(mixpanel!==undefined)mixpanel.track('password_change',eventData);*/
}


//links
export function triggerLinkOpenEvent(label) {
	if(debug)console.log('Links Open '+label);
	triggerGAEvent('Links','Open',label);
    /*if(mixpanel!==undefined)mixpanel.track('link',{label:label});*/
}


//Forms
export function triggerContactFormSubmittedEvent(formLabel){
	triggerGAEvent('Contact Form','Submit',formLabel);
    /*if(mixpanel!==undefined)mixpanel.track('contact_form_submit',{label:formLabel});*/
}

export function triggerCodeActivationCheck(success) {
	triggerGAEvent('Code Verification',success?'Valid':'Invalid');
    /*if(mixpanel!==undefined)mixpanel.track('promo_code_validation',{success:success});*/
}

export function triggerCodeActivationCompleted() {
	triggerGAEvent('Code Activation','Activated');
    /*if(mixpanel!==undefined)mixpanel.track('promo_code_activation');*/
}


//page section views
export function setPageSection(section) {
	if(debug)console.log(section);
    try {
        ga('send', 'pageview', '/?'+section);
    } catch (e){
        console.log(e);
    }

    /*if(mixpanel!==undefined)mixpanel.track('page_view',{label:section});*/
}

export function triggerGAEvent(category, action, label, value) {
	if(debug)console.log('GA Event triggered: '+label);
    try {
        if(value === undefined) {
            ga('send', 'event', category, action, label);
        }else{
            ga('send', 'event', category, action, label, value);
        }
    } catch (e) {
        console.log(e)
    }


}
